<template>
  <client-only>
    <div class="c_slider" :class="onOverlay ? 'c_slider--overlay' : ''" :style="sliderStyles">
      <agile
        class="c_slider--main"
        :class="[
          arrows ? 'c_slider--main--arrows--' + arrows : '',
          optionsForMain.infinite ? 'c_slider--infinite' : '',
        ]"
        ref="main"
        :options="optionsForMain"
        :as-nav-for="showThumbnails ? [$refs.thumbnails] : []"
        v-if="slideImageSources"
        @updateSlideIndex="updateSlideIndex"
        @keydown.native.left="goToPrevSlide"
        @keydown.native.right="goToNextSlide"
      >
        <Button
          class="c_slider__slide"
          @onClick="onMainItemClick(index)"
          v-for="(slide, index) in slides"
          unstyle
          reset
          :key="index"
        >
          <MediaVideo
            background
            loading="eager"
            :title="slide.alt"
            :source="slide.videoSource"
            :video-id="slide.videoId"
            v-if="videoPlayer && slide.videoId"
          />
          <MediaImage :alt="slide.alt || null" loading="eager" :sources="slide.sourcesMain" v-else />
          <Flex center class="c_box--mask c_slider__slide--video_icon" v-if="!videoPlayer && slide.videoId">
            <IconSVG
              stroke-color-var="white"
              handle="media_play"
              width="60"
              height="60"
              :replacements="{ 'Media - Play': $t('Media - Play') }"
            />
          </Flex>
          <Flex center class="c_box--mask c_slider__slide--hover_effect" v-if="!disableHoverEffect">
          </Flex>
          <Caption
            v-if="captions && slides[currentSlideIndex].caption"
            :content="captions && slides[currentSlideIndex].caption ? slides[currentSlideIndex].caption : null"
          >
          </Caption>
        </Button>
        <template slot="prevButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_left"
              :replacements="{ 'Arrow Left': $t('Previous Slide') }"
            />
          </div>
        </template>
        <template slot="nextButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_right"
              :replacements="{ 'Arrow Right': $t('Next Slide') }"
            />
          </div>
        </template>
      </agile>
      <agile
        class="c_slider--main"
        :class="[
          arrows ? 'c_slider--main--arrows--' + arrows : '',
          optionsForMain.infinite ? 'c_slider--infinite' : '',
        ]"
        ref="main"
        :options="optionsForMain"
        :as-nav-for="showThumbnails ? [$refs.thumbnails] : null"
        v-else-if="$slots.slides"
        @keydown.native.left="goToPrevSlide"
        @keydown.native.right="goToNextSlide"
      >
        <slot name="slides" />
        <template slot="prevButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_left"
              :replacements="{ 'Arrow Left': $t('Previous Slide') }"
            />
          </div>
        </template>
        <template slot="nextButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_right"
              :replacements="{ 'Arrow Right': $t('Next Slide') }"
            />
          </div>
        </template>
      </agile>
      <agile
        class="c_slider--thumbnails"
        :class="[
          arrows ? 'c_slider--thumbnails--arrows--' + arrows : '',
          optionsForThumbnails.infinite ? 'c_slider--infinite' : '',
        ]"
        ref="thumbnails"
        :options="optionsForThumbnails"
        :as-nav-for="showThumbnails ? [$refs.main] : []"
        v-if="showThumbnails && slideImageSources && slideTotal > 1"
        @keydown.native.left="goToPrevSlide"
        @keydown.native.right="goToNextSlide"
      >
        <Button
          class="c_slider__slide c_slider__slide--thumbnail"
          :class="{ 'is-active': currentSlideIndex === index }"
          v-for="(slide, index) in slides"
          :key="index"
          @onClick="goTo(index)"
          unstyle
          reset
        >
          <div class="c_box--full c_box--pos--relative">
            <MediaImage :alt="slide.alt || null" :sources="slide.sourcesThumbnails" />
            <Flex center class="c_box--mask c_slider__slide--video_icon" v-if="slide.videoId">
              <IconSVG
                stroke-color-var="white"
                handle="media_play"
                width="40"
                height="40"
                :replacements="{ 'Media - Play': $t('Media - Play') }"
              />
            </Flex>
            <div center class="c_box--mask c_slider__slide--hover_effect" v-if="!disableHoverEffect"></div>
          </div>
        </Button>
        <template slot="prevButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_left"
              :replacements="{ 'Arrow Left': $t('Previous Slide') }"
            />
          </div>
        </template>
        <template slot="nextButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_right"
              :replacements="{ 'Arrow Right': $t('Next Slide') }"
            />
          </div>
        </template>
      </agile>
      <agile
        class="c_slider--thumbnails"
        :class="[
          arrows ? 'c_slider--thumbnails--arrows--' + arrows : '',
          optionsForThumbnails.infinite ? 'c_slider--infinite' : '',
        ]"
        ref="thumbnails"
        :options="optionsForThumbnails"
        :as-nav-for="showThumbnails ? [$refs.main] : []"
        v-else-if="$slots.thumbnails && slideTotal > 1"
        @keydown.native.left="goToPrevSlide"
        @keydown.native.right="goToNextSlide"
      >
        <slot name="thumbnails" />
        <template slot="prevButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_left"
              :replacements="{ 'Arrow Left': $t('Previous Slide') }"
            />
          </div>
        </template>
        <template slot="nextButton">
          <div class="c_slider__arrow" :class="arrows ? 'c_slider__arrow--' + arrows : ''">
            <IconSVG
              :stroke-color-var="arrowColor"
              handle="arrow_right"
              :replacements="{ 'Arrow Right': $t('Next Slide') }"
            />
          </div>
        </template>
      </agile>
    </div>
  </client-only>
</template>

<script>
// import { log } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import Caption from 'Components/caption/Caption.vue';
import MediaImage from 'Components/image/MediaImage.vue';
import VueAgileOverride from 'Components/vue_agile_override/VueAgileOverride';

export default {
  components: {
    Button,
    Caption,
    Flex: () => import('Components/flex/Flex.vue'),
    IconSVG: () => import('Components/icon_svg/IconSVG.vue'),
    MediaImage,
    MediaVideo: () => import('Components/video/MediaVideo.vue'),
    agile: VueAgileOverride,
  },
  props: {
    arrows: { type: String, default: 'hidden' }, // hidden, inside, outside, overlay
    arrowColor: {
      type: String,
      default: 'gray_191',
    },
    sliderType: { type: String, default: 'normal' },
    autoplay: { type: Boolean, default: false },
    captions: { type: Boolean, default: false },
    onOverlay: { type: Boolean, default: false },
    disableHoverEffect: { type: Boolean, default: false },
    mainHeight: { type: String, default: '350px' },
    thumbnailsHeight: { type: String, default: '95px' },
    thumbnailsOnDesktop: Number,
    thumbnailsOnMobile: Number,
    rounded: { type: Boolean, default: false },
    showIndicators: { type: Boolean, default: false },
    showThumbnails: { type: Boolean, default: false },
    slideImageSources: Array,
    startingSlideIndex: Number,
    videoPlayer: { type: Boolean, default: false },
  },
  computed: {
    optionsForMain() {
      return {
        autoplay: this.autoplay && !this.showThumbnails,
        dots: this.showIndicators && this.slideTotal >= 2,
        fade: true,
        navButtons: ['inside', 'outside', 'overlay'].includes(this.arrows) && !this.showThumbnails,
        unagile: this.slideTotal < 2,
      };
    },
    optionsForThumbnails() {
      const slidesToShowVal = this.slideTotal < 5 ? this.slideTotal : 5;

      return {
        autoplay: this.autoplay,
        centerMode: false,
        dots: false,
        infinite: false,
        loop: true,
        navButtons: ['inside', 'outside', 'overlay'].includes(this.arrows),
        slidesToShow: slidesToShowVal, // Adjusted based on the actual number of thumbnails
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: this.totalThumbnailsShown(this.thumbnailsOnMobile || 3),
            },
          },
        ],
      };
    },
    slideTotal() {
      if (this.slideImageSources) {
        return this.slideImageSources.length;
      } else if (this.$slots.thumbnails) {
        return this.$slots.thumbnails.length;
      } else if (this.$slots.slides) {
        return this.$slots.slides.length;
      }

      return 0;
    },
    sliderStyles() {
      return {
        '--slider-border-radius': this.rounded ? '10px' : '0px',
        '--slider-main-height': this.mainHeight,
        '--slider-padding': this.arrows === 'inside' ? '30px' : '0',
        '--slider-arrow-distance-offset-xsm': this.calculateArrowDistance('xsm'),
        '--slider-arrow-distance-offset-sm': this.calculateArrowDistance('sm'),
        '--slider-arrow-distance-offset-md': this.calculateArrowDistance('md'),
        '--slider-arrow-distance-offset-lg': this.calculateArrowDistance('lg'),
        '--slider-arrow-distance-offset':
          this.arrows === 'overlay' ? '-40px' : this.arrows === 'inside' && this.$mq !== 'lg' ? '10px' : null,
        '--slider-thumbnails-height': this.thumbnailsHeight,
        '--slider-cursor': this.onOverlay ? 'default' : 'zoom-in',
      };
    },
    slides() {
      return this.slideImageSources || [];
    },
    computedSlidesToShow() {
      return this.totalThumbnailsShown(
        this.thumbnailsOnDesktop ? this.thumbnailsOnDesktop : this.slideImageSources ? 5 : 4
      );
    },
  },
  data() {
    return {
      currentSlideIndex: 0,
      hasRendered: false,
      flag: false,
    };
  },
  methods: {
    calculateArrowDistance(windowSize) {
      if (!this.onOverlay) {
        if (this.sliderType === 'normal') {
          return windowSize === 'xsm'
            ? '-30px'
            : windowSize === 'sm'
            ? '-17px'
            : windowSize === 'md'
            ? '-10px'
            : '30px';
        }
        if (this.sliderType === 'thumbnails') {
          return '0px';
        }
        return windowSize === 'xsm' ? '-50px' : '-40px';
      }
      if (this.onOverlay) {
        return windowSize === 'sm' ? '25px' : windowSize === 'md' ? '60px' : '80px';
      }
    },
    goTo(index, transition = true) {
      this.currentSlideIndex = index;
      this.$refs?.main?.goTo(index, transition);
      if (this.showThumbnails) {
        this.$refs?.thumbnails?.goTo(index, transition);
      }
    },
    goToPrevSlide() {
      if (this.$refs?.thumbnails) {
        this.$refs?.thumbnails?.goToPrev();
      }
      this.$refs?.main?.goToPrev();
    },
    goToNextSlide() {
      if (this.$refs?.thumbnails) {
        this.$refs?.thumbnails?.goToNext();
      }
      this.$refs?.main?.goToNext();
    },
    updateSlideIndex(index) {
      if (index < 0 || index >= this.slideImageSources.length) return;
      this.currentSlideIndex = index;
    },
    onMainItemClick(index) {
      this.$emit('mainItemClicked', index);
    },
    onThumbnailItemClick(index) {
      this.$emit('thumbnailItemClicked', index);
    },
    reloadMain() {
      // log('reload main fired');
      // log('main', this.$refs.main);
      this.$refs.main.reload();
      this.$refs.main.goTo(0);
      this.$refs.main.prepareSlidesClasses();
    },
    reloadThumbnails() {
      // log('reload thumbnails fired');
      // log('thumbnails', this.$refs.thumbnails);
      this.$refs.thumbnails.reload();
      this.$refs.thumbnails.goTo(0);
      this.$refs.thumbnails.prepareSlidesClasses();
    },
    reloadAll() {
      this.reloadMain();
      this.reloadThumbnails();
    },
    totalThumbnailsShown(maxNumber) {
      return this.slideTotal <= maxNumber ? this.slideTotal : maxNumber;
    },
  },
  mounted() {
    if (this.startingSlideIndex) {
      this.goTo(this.startingSlideIndex);
    }
    // log('Slider', this);
  },
};
</script>

<style lang="scss">
.c_slider__slide--thumbnail {
  &.is-active > div {
    border-bottom: 5px solid $color_yellow; // Adjust as per your design
  }
}
.c_slider--thumbnails {
  display: flex;
  justify-content: center; // Center thumbnails
  flex-wrap: nowrap; // Prevent wrapping

  .c_slider__slide--thumbnail {
    flex: 0 0 auto; // Prevent stretching of the thumbnail
    // Set a max-width if necessary to prevent over-expansion
  }
}
.c_slider {
  $slider: &;
  $_spacing: 14px;

  --slider-dots-default-color: #{$color_gray_191};
  --slider-dots-hover-color: #{$color_red};
  --slider-dots-active-color: #{$color_yellow};
  --slider-dots-active-hover-color: var(--slider-dots-hover-color);
  --slider-arrow-hover-color: #{$color_red};
  --slider-arrow-svg-height: #{px_to_rem(28)};
  --slider-arrow-svg-width: #{px_to_rem(17)};

  @at-root #{$slider}--overlay {
    @include mq(0, '', 'rem') {
      width: 180px !important;
    }
    @include mq(450, '', 'rem') {
      width: 350px !important;
    }
    @include mq($mq_sm_to_md, '', 'rem') {
      width: 70vw !important;
    }
    @include mq($mq_md_to_lg, '', 'rem') {
      width: 800px !important;
    }
  }

  padding: 0 var(--slider-padding, 0);
  position: relative;

  /*@at-root .c_box--yellow #{&}{
    --stroke-color: #{$color_black};
  }
  @at-root .c_box--black #{&}{
    --stroke-color: #{$color_white};
  }*/

  @at-root .c_box--yellow #{&} {
    --slider-dots-default-color: #{$color_gray_112};
    --slider-dots-active-color: #{$color_black};
    .c_icon_svg {
      --stroke-color: #{$color_gray_112} !important;
    }
  }

  img,
  iframe {
    border-radius: var(--slider-border-radius);
  }

  @at-root #{$slider}__slide {
    $slide: &;
    box-sizing: border-box;
    color: #fff;
    cursor: var(--slider-cursor);

    @at-root #{$slider}--main & {
      width: 100%;
      cursor: var(--slider-cursor);
    }

    @at-root #{&}--thumbnail {
      display: block;
      cursor: pointer;
      height: 100px;
      padding: 0 #{$_spacing / 2};
      transition: opacity 0.5s;
      & > div {
        border-bottom: 5px solid transparent;
        padding-bottom: 5px;
      }
      &.agile__slide--active > div {
        border-bottom-color: $color_yellow;
      }
      #{$slide}--hover_effect {
        height: calc(100% - 5px);
      }
      figure {
        height: 100%;
        overflow: hidden;
      }
      picture {
        display: flex;
        height: 100%;
      }
      img {
        object-fit: cover;
      }
    }
    @at-root #{$slide}--video_icon {
      position: absolute;
      z-index: 100;
    }
    @at-root #{$slide}--hover_effect {
      opacity: 0;
      z-index: 0;
      border-radius: var(--slider-border-radius);
      background: rgba($color_black_raw, 0.3);
      transition: opacity 0.5s ease, background 0.5s ease;
      #{$slide}:hover &,
      #{$slide}:focus & {
        opacity: 1;
        z-index: 50;
      }
    }
  }
  @at-root #{$slider}--thumbnails {
    margin: $_spacing -#{$_spacing / 2} 0;
    width: calc(100% + #{$_spacing});
  }
  @at-root .agile {
    $agile: &;

    @at-root #{$agile}__slides {
      max-width: 100%;
    }

    @at-root #{$agile}__slide {
      @at-root #{$slider}--thumbnails & {
        height: var(--slider-thumbnails-height, 85px);
      }
    }

    @at-root #{$agile}__dots {
      position: absolute;
      left: 50%;
      bottom: 15px;
      transform: translateX(-50%);
    }
    @at-root #{$agile}__dot {
      button {
        @include button_reset;
        @include hide_text;
        margin: 0 3px;
        height: 3px;
        width: 29px;
        transition: background-color 0.5s ease-out;
        position: relative;
        padding: px_to_rem(10) 0;
        &::after {
          content: '';
          width: 90%;
          height: 5px;
          background-color: var(--slider-dots-default-color);
          display: inline-block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto 0;
        }
        &:hover::after,
        &:focus::after {
          background-color: var(--slider-dots-hover-color);
        }

        @at-root #{$agile}__dot--current#{&} {
          &::after {
            background-color: var(--slider-dots-active-color);
          }
        }
      }
    }
    @at-root #{$agile}__nav-button {
      @include button_reset;
      position: absolute;
      top: 50%;
      width: 30px;
      transform: translateY(-50%);
      @at-root #{$slider}--main--arrows--overlay & {
        width: auto;
      }
      @at-root #{$slider}--thumbnails--arrows--overlay & {
        width: auto;
      }
      /* @at-root #{$slider}--main & {
        --slider-arrow-distance-offset: 14px;
      } */

      @at-root #{&}--prev {
        @include mq(0, '', 'rem') {
          left: calc(-30px - var(--slider-arrow-distance-offset-xsm, 0px));
        }
        @include mq(450, '', 'rem') {
          left: calc(-30px - var(--slider-arrow-distance-offset-sm, 0px));
        }
        @include mq($mq_sm_to_md, '', 'rem') {
          left: calc(-30px - var(--slider-arrow-distance-offset-md, 0px));
        }
        @include mq($mq_md_to_lg, '', 'rem') {
          left: calc(-30px - var(--slider-arrow-distance-offset-lg, 0px));
        }
        text-align: right;
        .c_icon_svg {
          margin-right: 0;
          margin-left: auto;
          svg {
            width: var(--slider-arrow-svg-width);
            height: var(--slider-arrow-svg-height);
          }
        }
      }
      @at-root #{&}--next {
        @include mq(0, '', 'rem') {
          right: calc(-30px - var(--slider-arrow-distance-offset-xsm, 0px));
        }
        @include mq(450, '', 'rem') {
          right: calc(-30px - var(--slider-arrow-distance-offset-sm, 0px));
        }
        @include mq($mq_sm_to_md, '', 'rem') {
          right: calc(-30px - var(--slider-arrow-distance-offset-md, 0px));
        }
        @include mq($mq_md_to_lg, '', 'rem') {
          right: calc(-30px - var(--slider-arrow-distance-offset-lg, 0px));
        }
        text-align: left;
        .c_icon_svg {
          margin-right: auto;
          margin-left: 0;
          svg {
            width: var(--slider-arrow-svg-width);
            height: var(--slider-arrow-svg-height);
          }
        }
      }

      @at-root #{$slider}--arrow_buttons {
        .c_quote_slider__content__wrapper {
          @include mq($mq_sm_to_md) {
            padding: 0 px_to_rem(100);
          }
          @include mq($mq_md_to_lg) {
            padding: 0 px_to_rem(120);
          }
        }
        .agile__nav-button {
          background-color: $color_yellow;
          color: $color_black;
          width: px_to_rem(30);
          height: px_to_rem(30);
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          .c_icon_svg {
            svg {
              height: px_to_rem(15);
              width: px_to_rem(15);
              g path {
                stroke: $color_black;
              }
              @include mq($mq_md_to_lg) {
                width: var(--slider-arrow-svg-width);
                height: var(--slider-arrow-svg-height);
              }
            }
          }
          @include mq($mq_sm_to_md) {
            height: px_to_rem(50);
            width: px_to_rem(50);
            position: absolute;
          }
          @include mq($mq_md_to_lg) {
            height: px_to_rem(75);
            width: px_to_rem(75);
          }
          @at-root #{&}--prev {
            @include mq(0, '', 'rem') {
              left: calc(50px - var(--slider-arrow-distance-offset-xsm, 0px));
            }
            @include mq(450, '', 'rem') {
              left: calc(20px - var(--slider-arrow-distance-offset-sm, 0px));
              bottom: px_to_rem(-50);
            }
            @include mq($mq_sm_to_md, '', 'rem') {
              left: calc(20px - var(--slider-arrow-distance-offset-md, 0px));
            }
            @include mq($mq_md_to_lg, '', 'rem') {
              left: calc(50px - var(--slider-arrow-distance-offset-lg, 0px));
            }
          }
          @at-root #{&}--next {
            @include mq(0, '', 'rem') {
              right: calc(50px - var(--slider-arrow-distance-offset-xsm, 0px));
            }
            @include mq(450, '', 'rem') {
              right: calc(20px - var(--slider-arrow-distance-offset-sm, 0px));
              bottom: px_to_rem(-50);
            }
            @include mq($mq_sm_to_md, '', 'rem') {
              right: calc(20px - var(--slider-arrow-distance-offset-md, 0px));
            }
            @include mq($mq_md_to_lg, '', 'rem') {
              right: calc(50px - var(--slider-arrow-distance-offset-lg, 0px));
            }
            text-align: left;
          }
        }
      }
      &:hover {
        .c_icon_svg {
          svg {
            --stroke-color: var(--slider-arrow-hover-color);
          }
        }
      }
    }
  }

  @at-root #{$slider}__arrow {
    @at-root #{&}--overlay {
      $overlay_arrow: &;
      --slider-arrow-svg-width: #{px_to_rem(10)};
      --slider-arrow-svg-height: #{px_to_rem(15)};
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background-color: $color_white;
      box-shadow: 0 px_to_rem(3) px_to_rem(6) rgba($color_black_raw, 0.3);
      border-radius: 50%;
      width: px_to_rem(40);
      height: px_to_rem(40);
      .agile__nav-button:hover &,
      .agile__nav-button:focus & {
        background-color: $color_black;
      }
      .c_icon_svg {
        width: auto;
        margin: 0 auto;
        --stroke-color: #{$color_black} !important;
        @include svg_stroke($width: #{px_to_rem(5)});
        .agile__nav-button:hover &,
        .agile__nav-button:focus & {
          svg {
            --stroke-color: #{$color_white} !important;
          }
        }
      }
    }
  }
  @at-root #{$slider}--infinite {
    .agile__slides {
      max-width: none;
    }
  }
}
</style>
